import React, {useEffect, useRef} from 'react';
import gsap from 'gsap';
import './logoCanvas.css';

interface LogoCanvasProps {
  logoUrl: string;
  percentage: number;
}

export const LogoCanvas = React.memo(function Logo({
  logoUrl,
  percentage,
}: LogoCanvasProps) {
  const imgElementRef = useRef<HTMLImageElement>(null);
  const timelineRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    if (imgElementRef.current) {
      timelineRef.current = gsap
        .timeline({paused: true})
        .to('.tour-client-logo-image', 0, {opacity: 1})
        .to('.tour-client-logo-image', 0.5, {opacity: 0})
        .to('.tour-client-logo-image', 0.0, {
          width: '10%',
          position: 'absolute',
          bottom: 0,
          right: 0,
        })
        .to('.tour-client-logo-image', 0.5, {opacity: 1});
      return () => {
        timelineRef.current?.kill();
      };
    }
  }, []);

  useEffect(() => {
    if (percentage > 10 && percentage < 99) {
      timelineRef.current?.play();
    } else {
      timelineRef.current?.reverse();
    }
  }, [percentage]);

  return (
    <div className="tour-client-logo-container">
      <img
        ref={imgElementRef}
        className="tour-client-logo-image"
        src={logoUrl}
        draggable={false}
        alt="LOGO"
      />
    </div>
  );
});
